import { PanelPlugin } from '@grafana/data';
import { MaintenanceRequestPanelOptions } from './types';
import { MaintenanceRequestPanel } from './components/MaintenanceRequestPanel';

export const plugin = new PanelPlugin<MaintenanceRequestPanelOptions>(MaintenanceRequestPanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      path: 'mainBtnText',
      name: 'Button text',
      description: 'Text for maintenance request button',
      defaultValue: 'Maintenance'
    }).addTextInput({
      path: 'dialogTitle',
      name: 'Dialog title',
      description: 'Text for maintenance request dialog title',
      defaultValue: 'New Maintenance Request'
    }).addTextInput({
      path: 'sensorText',
      name: 'Sensor',
      description: 'Sensor ID (or Sensor ID variable name)',
      defaultValue: '$sensor'
    }).addTextInput({
      path: 'publicApiText',
      name: 'Public API',
      description: 'Public API datasource name',
      defaultValue: 'public-api'
    }).addBooleanSwitch({
      path: 'disableAssetSelection',
      name: 'Disable Asset Selection',
      defaultValue: true
    });
});
