import React from 'react';
import { PanelProps } from '@grafana/data';
import { MaintenanceRequestPanelOptions, Asset, FileToUpload } from 'types';
import { Button, Checkbox, Field, FieldSet, Form, HorizontalGroup, Alert, LoadingPlaceholder, Modal, RadioButtonGroup, TextArea, Select } from '@grafana/ui';
import { getDataSourceSrv as getDataSourceService, loadPluginCss } from '@grafana/runtime';
import axios from 'axios';
import useState from 'react-usestateref'


interface Props extends PanelProps<MaintenanceRequestPanelOptions> { }

export const MaintenanceRequestPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  loadPluginCss({
    dark: 'plugins/icb-maintenancerequest-panel/css/base.css',
    light: 'plugins/icb-maintenancerequest-panel/css/base.css',
  });

  const priorityOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
  ];
  const reqImgInput = React.useRef<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [grafanaOrgId, setGrafanaOrgId, refGrafanaOrgId] = useState(undefined as number | undefined);
  const [priorityVal, setPriorityVal] = useState(undefined as number | undefined);
  const [sensorId, setSensorId, refSensorId] = useState('');
  const [selectedAsset, setSelectedAsset, refSelectedAsset] = useState(undefined as Asset | undefined);
  const [description, setDescription] = useState(undefined as string | undefined);
  const [loadingMsg, setLoadingMsg, refLoadingMsg] = useState(undefined as string | undefined);
  const [proxyUrl, setProxyUrl, refProxyUrl] = useState('');
  const [requestState, setRequestState] = useState({ ok: undefined as any, error: undefined as any, warn: undefined as any });
  const [shutdownRequired, setShutdownRequired] = useState(false);
  const [includeMachineAlarms, setIncludeMachineAlarms] = useState(false);
  const [assetList, setAssetList, refAssetList] = useState([] as Asset[]);
  const [imagesToUpload, setImagesToUpload, refImagesToUpload] = useState([] as FileToUpload[]);
  const alarmsLink = window.location.origin+`/d/alarms-summary-1/alarms-summary?var-sensor=${sensorId}&var-department=All&from=now-24h&to=now`;

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setLoadingMsg("Loading...");
    setGrafanaOrgId(parseInt(replaceVariables("${__org}"), 10));
    setSensorId(replaceVariables(options.sensorText));
    clearForm();
    getProxyURL().then(() => {
      loadAssetList().then(() => {
        setAssetIdFromSensorid(refSensorId.current);
        setModalIsOpen(true);
        setLoadingMsg(undefined);
      });
    });
  }

  const getProxyURL = () => {
    const { publicApiText } = options;
    if (refProxyUrl.current !== '') {
      return Promise.resolve();
    }
    const srv = getDataSourceService();
    return srv.get(publicApiText).then(result => {
      const us = result as any;
      setProxyUrl(`/api/datasources/${us.id}/resources`);
    });
  }

  const clearForm = () => {
    setLoadingMsg(undefined);
    setShutdownRequired(false);
    setIncludeMachineAlarms(false);
    setImagesToUpload([]);
    setPriorityVal(0);
    setDescription('');
    setRequestState({ ok: undefined, warn: undefined, error: undefined });
  }

  const loadAssetList = () => {
    if (refAssetList.current?.length > 0) {
      return Promise.resolve();
    }

    const URL = window.location.origin + refProxyUrl.current + `/publicapi/api/maintenance/assetList/${refGrafanaOrgId.current}`;
    console.log(URL);
    return axios
      .get(URL)
      .then((response: any) => {
        console.log(response);
        if (response.status === 200 && response.data) {
          setAssetList(response.data.map((a: any) => {
            return {
              name: a.AssetName,
              id: a.Id,
              sensorId: a.SensorID
            } as Asset;
          }));
        }
      })
      .catch((e: any) => {
        console.log(e);
        setAssetList([]);
      });
  }

  const onAssetChanged = (option: any) => {
    setAssetIdFromSensorid(option.sensorId);
  }
  const setAssetIdFromSensorid = (sId: string) => {
    const asset = refAssetList.current.find((a) => { return a.sensorId === sId });
    if (asset) {
      setSelectedAsset(asset);
    }
    else {
      setRequestState({
        ok: undefined, error: undefined,
        warn: "To submit a Request the machine must first be converted to an Asset!"
      });
    }
  }

  const onSubmit = () => {
    if (refGrafanaOrgId.current === undefined || refGrafanaOrgId.current === 0
      || priorityVal === undefined || priorityVal < 1 || priorityVal > 3
      || refSelectedAsset.current === undefined || refSelectedAsset.current.id === 0
      || description === undefined || description.trim().length === 0) {
      setRequestState({
        ok: undefined, warn: undefined, error: {
          response: {
            data: "Missing required data!"
          }
        }
      });
      return;
    }

    setLoadingMsg("Sending...");
    let data = new FormData();
    data.append("GrafanaOrgId", refGrafanaOrgId.current.toString());
    data.append("AssetId", refSelectedAsset.current?.id.toString());
    data.append("LoggedInUser", replaceVariables("${__user.login}"));
    data.append("Description", description);
    data.append("Priority", priorityVal.toString());
    data.append("ShutdownRequired", shutdownRequired.toString());
    data.append("IncludeMachineAlarms", includeMachineAlarms.toString());
    if (imagesToUpload.length > 0) {
      for (const file of imagesToUpload) {
        data.append("Files", file.File, file.Name);
      }
    }

    const URL = window.location.origin + refProxyUrl.current + '/publicapi/api/maintenance/submit/';

    axios({
      method: "post",
      url: URL,
      data: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((response: any) => {
        console.log(response);
        setRequestState({ ok: response, warn: undefined, error: undefined });
        setLoadingMsg(undefined);
      })
      .catch((e: any) => {
        console.log(e);
        setRequestState({ ok: undefined, warn: undefined, error: e });
        setLoadingMsg(undefined);
      });
  }

  const onImagesSelected = (event: any) => {
    if (event?.target?.files === undefined || event.target.files.length === 0) {
      return;
    }

    if (!imagesToUpload) {
      setImagesToUpload([]);
    }

    function readAndPreview(file: any) {
      const reader = new FileReader();
      reader.addEventListener(
        "load", () => {
          let foundImgIdx = getImageIndex(refImagesToUpload.current, file.name)
          if (foundImgIdx === -1) {
            let dataUrl = reader.result;
            setImagesToUpload([...refImagesToUpload.current,
            {
              ImageData: dataUrl,
              ImageBackgroundUrl: `url('${dataUrl}')`,
              Name: file.name,
              ContentType: file.type,
              File: file
            }
            ]);
          }
        },
        false,
      );

      reader.readAsDataURL(file);
    }
    Array.prototype.forEach.call(event.target.files, readAndPreview);
  }

  const ViewRequestImage = (imageData: any) => {
    let image = new Image();
    image.src = imageData;

    let w = window.open("");
    if (w) {
      w.document.write(image.outerHTML);
    }
  }

  const DeleteRequestImage = (imageName: any) => {
    setImagesToUpload(imagesToUpload.filter(i => i.Name !== imageName));
  }

  function onIncludeMachineAlarmsChange(val: any){
    setIncludeMachineAlarms(val);
    if(val){
      setDescription(description+`\nClick here to view relevant machine alarms: ${alarmsLink}`);
    }else{
      setDescription(description!.replace(/\nClick here to view relevant machine alarms:.*$/, ''));
    }
  }


  function getImageIndex(currentImgList: FileToUpload[], imageName: any): number {
    for (let i = 0; i < currentImgList.length; i++) {
      if (currentImgList[i].Name === imageName) {
        return i;
      }
    }
    return -1;
  }

  const renderForm = () => {
    if (refLoadingMsg.current) {
      return (<LoadingPlaceholder text={refLoadingMsg.current} />);
    }

    let alert: any;
    if (requestState.ok?.data) {
      const obj = JSON.parse(requestState.ok.data);
      return (<Alert title="Success"
        severity='success'
        onAbort={closeModal}
      >{<div>The request was created with number <b>{obj}</b></div>}</Alert>);
    }
    else {
      if (requestState.error?.response?.data) {
        alert = <Alert title="Error"
          severity='error'
        >{<div>{requestState.error.response.data}</div>}</Alert>;
      }
      else if (requestState.warn !== undefined) {
        alert = <Alert title="Warning"
          severity='warning'
        >{<div>{requestState.warn}</div>}</Alert>;
      }
      else if (requestState.error) {
        alert = <Alert title="Error"
          severity='error'
        >{<div>Check the console for more details!</div>}</Alert>;
      }
    }

    
    return (
      <Form onSubmit={onSubmit} style={{ width: "450px!important" }} className="mms-request-dialog">
        {({ register, errors }) => (
          <>
            <FieldSet disabled={loadingMsg !== undefined}>
              <HorizontalGroup  >
                <Field label="Asset" required={true}>
                  <Select
                    className="asset-select"
                    options={assetList}
                    value={selectedAsset}
                    disabled={options.disableAssetSelection}
                    getOptionLabel={a => a.name}
                    getOptionValue={a => a.id}
                    onChange={onAssetChanged}
                  />
                </Field>
              </HorizontalGroup>
              <Field label="Priority" required={true}>
                <RadioButtonGroup
                  options={priorityOptions}
                  value={priorityVal}
                  onChange={setPriorityVal}
                />
              </Field>
              <Field label="Description" required={true}>
                <TextArea className="description" maxLength={1000} name="Description" value={description} onChange={(e: any) => setDescription(e.target.value)} />
              </Field>
              {includeMachineAlarms && (
              <div style={{ textAlign: 'right' }}>
                <a href={alarmsLink} target="_blank" rel="noopener noreferrer" style={{color: '#3971dd'}}>
                  Machine alarms(link)
                </a>
             </div>
            )}
              <FieldSet>
                <input ref={reqImgInput} style={{ display: "none" }}
                  type="file" accept="image/*" multiple
                  onChange={onImagesSelected}
                />
                <Button type='button' variant="secondary"
                  onClick={() => {
                    if (reqImgInput.current) {
                      reqImgInput.current.value = '';
                      reqImgInput.current.click();
                    }
                  }}>
                  Add images
                </Button>
                <div style={{ float: "right" }}>
                  <Checkbox
                    name="ShutdownRequired"
                    value={shutdownRequired}
                    onChange={(e: any) => setShutdownRequired(e.target.checked)}
                    label="Shutdown required"
                  />
                </div>
                <br/>
                <div style={{ float: "right" }}>
                  <Checkbox
                    name="IncludeMachineAlarms"
                    value={includeMachineAlarms}
                    onChange={(e: any) => onIncludeMachineAlarmsChange(e.target.checked)}
                    label="Include machine alarms"
                  />
                </div>
              </FieldSet>
              <div className="img-list-container" style={{ display: imagesToUpload.length > 0 ? "block" : "none" }}>
                <div className="img-list-horizontal-scroll" style={{ width: imagesToUpload.length * 180 + 'px' }}>
                  {imagesToUpload.map((image, idx) => {
                    return <div className="imageBtnWrapper" key={"image" + idx}>
                      <div className="img"
                        onClick={() => ViewRequestImage(image.ImageData)}
                        style={{ width: "180px", height: "100px", backgroundImage: image.ImageBackgroundUrl }}>
                      </div>
                      <div style={{ top: "0px", right: "0px", position: "absolute" }}>
                        <span className="img-btn" onClick={() => DeleteRequestImage(image.Name)} style={{ top: "0px", right: "20px" }}>
                          <i className="fas fa-trash" style={{ color: "red", textDecoration: "none" }}></i>
                        </span>
                        {/* <span className="img-btn" style={{ top: "20px" }}>
                          <a href={image.ImageData?.toString()} target="_blank" rel="noreferrer" download={image.Name}>
                            <i className="fas fa-download" style={{ color: "green", textDecoration: "none" }}></i>
                          </a>
                        </span> */}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </FieldSet>
            {alert}
            <HorizontalGroup>
              <Button variant="primary" type="submit" disabled={loadingMsg !== undefined || selectedAsset === undefined}>Send</Button>
              <Button variant="secondary" type='button' onClick={closeModal}>Cancel</Button>
            </HorizontalGroup>
          </>
        )
        }
      </Form>
    );
  }

  return (
    <div  >
      <div className={"mmsr-main-btn"}>
        <Button onClick={() => openModal()} title={options.mainBtnText} variant="secondary" >
          <i className="fas fa-wrench" style={{ marginRight: "5px" }}></i>{options.mainBtnText}
        </Button>
      </div>

      <Modal title={options.dialogTitle} isOpen={modalIsOpen} onDismiss={closeModal}>
        {renderForm()}
      </Modal>
    </div>
  );
};
